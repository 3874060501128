import axios from "axios";

/**
 * General functions
 *
 * @author Koen Vermeulen
 */

/**
 * Request
 * Makes an axios requesta
 *
 * @param config
 * @returns Promise void
 */
export const Request = (config: any) => {
  let url: string = config.url;
  if (config.method === "get") {
    let params = new URLSearchParams(config.data).toString();
    url = `${url}?${params}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .request({
        url: url,
        method: config.method || "get",
        data: config.data,
        headers: {
          ...config.headers,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export function formatNumber(num: number): string {
  if (num >= 1e15) {
    return (num / 1e15).toFixed(2).replace(/\.00$/, "") + "Q"; // Quadrillions
  }
  if (num >= 1e12) {
    return (num / 1e12).toFixed(2).replace(/\.00$/, "") + "T"; // Trillions
  }
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num.toString();
}
