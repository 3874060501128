import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className=" w-full p-8">
      <div className="prose flex flex-col gap-2">
        <h1>INTRODUCTION</h1>
        <p>
          Reform DAO respects your privacy and processes personal data in accordance with the
          European General Data Protection Regulation (GDPR). In this Privacy and Cookie Policy
          (hereafter ‘Policy’) we address the usage of cookies and how we handle and protect your
          personal data.
        </p>

        <h1>MORE PRECISE, THIS POLICY ADDRESSES</h1>
        <p>- What personal data do we collect and how;</p>
        <p>- For what purposes and on what grounds do we process your personal data;</p>
        <p>- For how long do we keep your personal data;</p>
        <p>- With whom we share your personal data;</p>
        <p>- The rights you have as the data subject; and,</p>
        <p>- How your personal data is protected.</p>
        <p>
          This Policy applies to all persons who visit our websites and of whom Reform DAO processes
          personal data, such as clients, prospects, persons who have subscribed to newsletters or
          events, and persons who have applied for a job at Reform DAO.
        </p>

        <h1>WHAT ARE PERSONAL DATA?</h1>
        <p>
          Personal data is any information relating to an identified or identifiable natural person.
          Personal data that we process may include:
        </p>
        <p>- Basic information, such as your first and last name, prefix, and title;</p>
        <p>- Contact details, such as your e-mail address, postal address, and phone number;</p>
        <p>
          - Personal data you provide us for the purpose of becoming our client, such as your
          nationality, date of birth, country of birth, residence address, TIN-number, and bank
          account number;
        </p>
        <p>
          - Personal data you provide us for the purpose of attending events or meetings, such as
          access and dietary requirements;
        </p>
        <p>
          - Personal data you may provide us for the purpose of a job application, such as your full
          name, date of birth, address, phone number, nationality, marital status, and any other
          personal data set out in your application;
        </p>
        <p>
          - Any other personal data relating to you that you may provide us or that we may obtain in
          relation to the purposes and based on grounds set out below;
        </p>
        <p>
          We collect this personal data because you provided this data to us. For example, you may
          provide data when entering into an agreement with us, by entering your data on our website
          (www.reformdao.com), by giving us your business card or by applying for a job. We may also
          collect your personal data from other sources, such as from local counsel, counterparties,
          the Trade Register, the Land Registry or by using publicly available sources. If you are a
          mere visitor to our website, we will not collect any personal data.
        </p>

        <h1>PURPOSES AND LEGAL BASIS FOR THE PROCESSING OF PERSONAL DATA</h1>
        <p>Reform DAO Labs may process your personal data for the following purposes:</p>
        <p>- To identify and accept you as a client;</p>
        <p>- To provide our services;</p>
        <p>- To comply with our legal and regulatory obligations;</p>
        <p>
          - For marketing and business development activities, such as news updates, invitations for
          our events, and other marketing communications that may be of interest to you;
        </p>
        <p>
          - To handle your job application or subscription to any of our recruitment services and
          events.
        </p>
        <p>We will process your personal data using one or more of the following legal grounds:</p>
        <p>- Performance of a contract;</p>
        <p>- Compliance with a legal obligation;</p>
        <p>- Legitimate interest;</p>
        <p>- Your consent.</p>

        <h1>SHARING OF PERSONAL DATA</h1>
        <p>
          In some cases, we may also share your personal data with third parts. This may include,
          but is not limited to:
        </p>
        <p>
          - Third pares relevant to the investment services that we provide, such as the fund
          administrator, regulatory authorities, and governmental institutions.
        </p>
        <p>
          - Third pares that we engage with, such as supervisory authorities and other bodies, in
          order to comply with legal obligations.
        </p>
        <p>
          - Third-party suppliers in connection with the processing of your personal data for the
          purposes described in this Policy, such as IT providers, communication service providers,
          or other suppliers to whom we outsource certain support services.
        </p>
        <p>
          - We will only share your personal data with these third pares for the purposes and on the
          legal grounds stated in this Policy.
        </p>
        <p>
          - Third parties to whom we transfer your personal data are themselves responsible for
          compliance with privacy legislation. Reform DAO is neither responsible nor liable for the
          processing of your personal data by these third pares.
        </p>
        <p>
          - To the extent that a third party processes your personal data as a data processor of
          Reform DAO, Reform DAO will conclude a processor agreement with such party that meets the
          requirements set out in the GDPR.
        </p>
        <p>
          - To be able to provide our services, it may be necessary for us to transfer your personal
          data to a recipient in a country outside of the European Economic Area. In that case,
          Reform DAO will ensure that the data transfer is compliant with the applicable law.
        </p>

        <h1>HOW LONG DO WE KEEP YOUR DATA?</h1>
        <p>
          Your personal data is kept in accordance with the GDPR. Your personal data is processed up
          until the moment that you inform Reform DAO that you no longer wish to receive any digital
          notifications and/or Reform DAO stops sending you newsletters, white papers, invitations
          to events, or other digital mailings. Your personal data will be erased if:
        </p>
        <p>
          - It appears that your email address is no longer in use (for example when error messages
          are received);
        </p>
        <p>
          - The consent based on which your personal data is processed and/or stored is withdrawn by
          you;
        </p>
        <p>
          - The aforementioned legal grounds on which your personal data is processed expire because
          for example if your company ceases to exist, or you have had any contact with Reform DAO
          for a period of 5 years (notwithstanding possible legal obligations).
        </p>

        <h1>CONTACT</h1>
        <p>
          If you have any questions or comments about our Policy or the processing of your personal
          data, please send a request in that regard to Reform DAO please send a message to the DAO
          contributors via the official Reform DAO discord channel. If you want to know more about
          this subject or have a complaint about the abuse of your personal data.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
