import growityLogo from "./../assets/svg/partnerLogos/growityLogo.svg";
import gateLogo from "./../assets/svg/partnerLogos/gateLogo.svg";
import bitgetLogo from "./../assets/svg/partnerLogos/bitgetLogo.svg";
import binanceLogo from "./../assets/svg/partnerLogos/binanceLogo.svg";
import bybitLogo from "./../assets/svg/partnerLogos/bybitLogo.svg";
import mexcLogo from "./../assets/svg/partnerLogos/mexcLogo.svg";
import okxLogo from "./../assets/svg/partnerLogos/okxLogo.svg";
import cryptoComLogo from "./../assets/svg/partnerLogos/cryptoComLogo.svg";
import venomLogo from "./../assets/svg/partnerLogos/venomLogo.svg";
import decubateLogo from "./../assets/svg/partnerLogos/decubateLogo.svg";
import singularityLogo from "./../assets/svg/partnerLogos/singularity_logo.svg";
import magicSquareLogo from "./../assets/svg/partnerLogos/magic_square_logo.svg";
import avalancheLogo from "./../assets/svg/partnerLogos/avalance_logo.svg";

import dyorExchangeLogo from "./../assets/svg/partnerLogos/dyorExchangeLogo.svg";
import galaxisLogo from "./../assets/svg/partnerLogos/galaxisLogo.svg";
import privateAILogo from "./../assets/svg/partnerLogos/privateAILogo.svg";
import nuklaiLogo from "./../assets/svg/partnerLogos/nuklaiLogo.svg";

export type ReformPartner = {
  link: string;
  logo: string;
  display: string;
};

export const mainPartners: ReformPartner[] = [
  {
    link: "https://growity.io",
    logo: growityLogo,
    display: "Growity",
  },
  {
    link: "https://gate.io",
    logo: gateLogo,
    display: "Gate",
  },
  {
    link: "https://bitget.com",
    logo: bitgetLogo,
    display: "Bitget",
  },
  {
    link: "https://binance.com",
    logo: binanceLogo,
    display: "Binance",
  },
  {
    link: "https://bybit.com",
    logo: bybitLogo,
    display: "Bybit",
  },
  {
    link: "https://mexc.com",
    logo: mexcLogo,
    display: "MEXC",
  },
  {
    link: "https://okx.com",
    logo: okxLogo,
    display: "OKX",
  },
  {
    link: "https://crypto.com",
    logo: cryptoComLogo,
    display: "Crypto.com",
  },
  {
    link: "https://venom.foundation/",
    logo: venomLogo,
    display: "Venom",
  },
  {
    link: "https://decubate.com",
    logo: decubateLogo,
    display: "Decubate",
  },
  {
    link: "https://singularitydao.ai/",
    logo: singularityLogo,
    display: "Singularity DAO",
  },
  {
    link: "https://launchpad.magicsquare.io/",
    logo: magicSquareLogo,
    display: "Magic Square",
  },
];

export const sidePartners: ReformPartner[] = [
  {
    link: "https://dyor.exchange/",
    logo: dyorExchangeLogo,
    display: "DYOR",
  },
  {
    link: "https://galaxis.xyz/",
    logo: galaxisLogo,
    display: "Galaxis",
  },
  {
    link: "https://www.private-ai.com/en/redact/",
    logo: privateAILogo,
    display: "PrivateAI",
  },
  {
    link: "https://www.private-ai.com/en/redact/",
    logo: avalancheLogo,
    display: "Avalanche",
  },
];
