import React from "react";
import {
  BASEPLATFORM_URL,
  PlatformRoute,
  platformRoutes,
} from "../../library/platformRoutes";

import Card from "../Card";
import logo from "../../assets/svg/reformdaoLogoSmall.svg";
import { Button } from "./../../../src/shadcn-ui/ui/button";
import HamburgerMenu from "../HamburgerMenu";

type Props = {};

export default function Header({}: Props) {
  return (
    <>
      <DesktopHeader />
      <MobileHeader />
    </>
  );
}

function DesktopHeader() {
  return (
    <Card className="hidden justify-between items-center py-4 px-5 lg:flex">
      <Logo divider />
      <Menu />
      <Buttons />
    </Card>
  );
}
function MobileHeader() {
  return (
    <div className="flex justify-between w-screen items-center py-4 px-5 lg:hidden bg-transparent absolute top-0 left-0 z-50">
      <Logo divider={false} />
      <HamburgerMenu menuRoutes={platformRoutes}>
        <Menu />
        <div className="mt-10 flex justify-center">
          <Buttons />
        </div>
      </HamburgerMenu>
    </div>
  );
}

function Logo({ divider }: { divider: boolean }) {
  return (
    <div className={` ${divider ? "border-r border-[#303030] pr-5 mr-6" : ""}`}>
      <img src={logo} />
    </div>
  );
}
function Menu() {
  return (
    <div className="w-full flex flex-col lg:flex-row items-center gap-4 lg:gap-8">
      <MenuItem route={{ link: "/", display: "Home" }} selected></MenuItem>
      {platformRoutes.map((platformRoute) => {
        return <MenuItem route={platformRoute} key={platformRoute.link} />;
      })}
    </div>
  );
}
function MenuItem({
  route,
  selected,
}: {
  route: PlatformRoute;
  selected?: boolean;
}) {
  return (
    <a href={route.link}>
      <div
        className={` duration-400 ${
          selected
            ? "text-primary hover:text-primary-dark"
            : "text-[#B2B1AD] hover:text-primary"
        }`}
      >
        {route.display}
      </div>
    </a>
  );
}

function Buttons() {
  return (
    <div className="flex">
      <a href={BASEPLATFORM_URL}>
        <Button>LAUNCH APP</Button>
      </a>
    </div>
  );
}
