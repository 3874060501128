const LOCAL_URL = "http://localhost:4000";

const API_URL = "";

const PRODUCTION = false;

const SERVER_URL = PRODUCTION ? API_URL : LOCAL_URL;

export const BASE_URL = SERVER_URL;

export const GET_TVL_URL =
  "https://mkl2z286c7.execute-api.eu-west-1.amazonaws.com/prod/overview";

export const GET_TRADINGSTATUS_URL =
  "https://pubapi.reformdao.com/info/last-total-volume";
