import React from "react";
import Card from "../Card";
import backgroundImage from "./../../assets/img/reformHomepageLeftBlockStatic.png";

import reformTrailer from "./../../assets/video/homepageLaunchVideo.mp4";
import stayTunedPlaceholder from "./../../assets/svg/reformStayTunedPlaceholder.svg";
import HomepageHero from "./HomepageHero";
import { Button } from "./../../shadcn-ui/ui/button";

type Props = {};

export default function HomepageContent({}: Props) {
  return (
    <div className="flex flex-col lg:grid grid-rows-[2fr_3fr] w-full gap-4 relative h-full overflow-hidden lg:max-h-[calc(100vh-105px)] ">
      <div className="">
        <HomepageHero />
      </div>

      <div className="lg:grid flex flex-col grid-cols-[1fr_auto] gap-4 lg:overflow-hidden lg:h-full h-fit ">
        <div className="h-full min-w-[400px]">
          <LeftBlock />
        </div>
        <div className="h-full lg:w-fit  overflow-hidden bg-[#08090B]">
          <RightBlock />
        </div>
      </div>
    </div>
  );
}

function LeftBlock() {
  return (
    <Card className="grow-1 shrink-1 self-auto basis-auto p-8 py-6 relative bg-transparent flex gap-8 h-full flex-col h-sm:py-4 h-sm:px-6">
      <h2 className="lg:text-3xl h-sm:text-2xl text-xl mt-3 max-w-[400px]">
        MARKET MAKING FOR YOUR PROJECT
      </h2>
      <div className="flex flex-col justify-center items-center h-full w-full text-center gap-8">
        <h3 className="text-3xl font-[500]">
          MAKERS.
          <br />
          CHANGERS.
          <br />
          DISRUPTORS.
        </h3>
        <p className="text-xs max-w-[300px] text-white/70">
          CONNECT WITH <span className="text-primary">REFORM DAO</span> TO GET
          AN INTRODUCTION AND LEARN MORE ABOUT OUR MARKET MAKING SERVICES.
        </p>
        <a
          href="https://reformdao.forms.app/marketmaking-applications"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant={"outline"} className="bg-black" size={"lg"}>
            GET IN TOUCH
          </Button>
        </a>
      </div>
      <img
        src={backgroundImage}
        alt="reform background"
        className="absolute top-0 left-0 object-cover w-full h-full -z-10"
      />
    </Card>
  );
}
function RightBlock() {
  const now = new Date();
  const openDate = new Date(Date.UTC(2024, 7, 29, 14, 0, 0));
  const revealed = now > openDate;

  return (
    <Card className="relative !bg-[#0A0B08] h-full ">
      {revealed && (
        <a
          href="https://www.gate.io/trade/RFRM_USDT"
          className="absolute lg:bottom-8 bottom-5 lg:right-8 left-8 lg:left-[unset] z-20"
        >
          <Button variant={"outline"} className="bg-black text-xs">
            EXCHANGES
          </Button>
        </a>
      )}
      {revealed ? (
        <video
          src={reformTrailer}
          autoPlay
          muted
          loop
          className="h-full w-auto relative lg:pb-0 pb-20"
          width={1920}
          height={1080}
        ></video>
      ) : (
        <img
          src={stayTunedPlaceholder}
          alt="reform stay tuned"
          className="h-full w-auto relative"
          width={1470}
          height={1190}
        ></img>
      )}
    </Card>
  );
}
