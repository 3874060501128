import React from "react";
import Header from "../components/homepage/Header";
import HomepageContent from "../components/homepage/HomepageContent";
import HomePageSideBar from "../components/homepage/HomePageSideBar";

type Props = {};

export default function Homepage({}: Props) {
  return (
    <div className="flex flex-col w-screen lg:h-screen h-full lg:max-h-screen max-w-screen overflow-x-hidden lg:overflow-y-hidden">
      <Header />
      <div className="lg:grid flex flex-col grid-cols-[7fr_2fr] lg:p-4 gap-4 w-full h-full">
        <HomepageContent />
        <HomePageSideBar />
      </div>
    </div>
  );
}
