import React from "react";

type Props = {};

export default function TermsOfService({}: Props) {
  return (
    <div className=" w-full p-8">
      <div className="prose flex flex-col gap-2">
        <h1>CONSENT</h1>
        <p>
          Please read the following legal notice/terms and conditions of use (“Terms and Conditions
          of Use”) carefully before accessing the website of Reform DAO. By accessing the website of
          Reform DAO, you confirm that you have read and understood the Terms and Conditions of Use
          and agree to all of the terms set out herein. If you do not understand or do not agree to
          any of the terms contained in the Terms and Conditions of Use, we advise you not to
          proceed or enter into an agreement with Reform DAO.
        </p>

        <h1>RESTRICTED ACCESS</h1>
        <p>
          The website of Reform DAO must not be accessed by any person subject to a jurisdiction
          (e.g., due to that person’s nationality and/or place of residence) in which publication of
          the content of the website of Reform DAO or access to it is prohibited for any reason.
          Persons to whom these restrictions apply are prohibited from accessing this website.
        </p>

        <h1>INTERNATIONAL SANCTION LIST</h1>
        <p>
          Reform DAO is committed to complying with international sanctions and will not provide
          access to any individuals or entities from countries listed on the international sanction
          list. This includes, but is not limited to, countries such as Iran, North Korea, Syria,
          and Sudan.
        </p>
        <p>
          Furthermore, Reform DAO will not provide access to individuals or entities from the United
          States of America, as the company is not registered with the U.S. Securities and Exchange
          Commission and is therefore unable to offer any products or services to U.S. citizens or
          residents.
        </p>
        <p>
          By accessing and using the services provided by Reform DAO, you agree to comply with these
          terms and conditions and confirm that you are not a resident or citizen of any country
          listed on the international sanction list, nor a citizen or resident of the United States
          of America.
        </p>

        <h1>NO OFFER</h1>
        <p>
          Information and opinions published on this website shall not be construed as any form of
          promotion, recommendation, inducement, offer, or solicitation to (i) purchase or sell any
          cryptocurrencies, (ii) transact any other business, or (iii) enter into any other legal
          transactions. Information and opinions published on this website are provided for
          information purposes only. The services described may not be suitable for you or may be
          unavailable.
        </p>

        <h1>NO ADVICE</h1>
        <p>
          Information and opinions published on this website do not constitute, nor are they
          intended to provide or be construed as investment advice, or any legal, tax, financial, or
          other advice. Such information and opinions should not be relied upon or used as a basis
          for decision-making. Please consult an expert in the appropriate field before deciding to
          take any specific action. No information contained on or accessed from the website of
          Reform DAO should be construed as creating any contractual relationship between the
          providers of such information and website users.
        </p>

        <h1>NO WARRANTY</h1>
        <p>
          Reform DAO has exercised the utmost care in compiling the information contained on this
          website. Information provided on this website is constantly updated and checked for
          accuracy. However, neither Reform DAO nor its contracting partners make any representation
          or warranty (whether express or implied) that any information published on the website of
          Reform DAO is accurate, reliable, up-to-date, or complete. In particular, Reform DAO is
          under no obligation whatsoever to update or remove outdated information or opinions from
          this website or to mark them as being outdated. The information and opinions contained on
          this website are subject to change at any time without notice.
        </p>
        <p>
          Moreover, no warranty is given that this website will operate error-free or without
          interruption, that any faults will be corrected, or that this website and servers from
          which information is accessible will be free of viruses, trojan horses, worms, software
          bombs, or other harmful components and programs, and Reform DAO accepts no liability in
          respect thereof.
        </p>
        <p>
          All information available on this website, including but not limited to financial market
          data, price data, reports, research, and other financial information is based on carefully
          selected sources that are deemed to be reliable. All information is provided to users on
          an “as is” and “as available” basis without any representation or warranty either express
          or implied as to the quality, originality, non-infringement of intellectual property
          rights, or fitness for a particular purpose.
        </p>
      </div>
    </div>
  );
}
