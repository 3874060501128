import React, { useState } from "react";
import { Rotate as Hamburger } from "hamburger-react";
import { PlatformRoute } from "@/library/platformRoutes";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./../shadcn-ui/ui/sheet";

type Props = { menuRoutes: PlatformRoute[]; children: React.ReactNode };

export default function HamburgerMenu({ menuRoutes, children }: Props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <Sheet
      open={isOpen}
      onOpenChange={(e) => {
        setOpen(e);
      }}
    >
      <Hamburger toggled={isOpen} toggle={setOpen} size={28} color="#00FFC2" />
      <SheetContent className="bg-black border-neutral-800 pt-14">
        {children}
      </SheetContent>
    </Sheet>
  );
}
